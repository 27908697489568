var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h4", { staticClass: "mb-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.resources.Filters.i18n) || _vm.resources.Filters.name
              ) +
              "\n    "
          )
        ]),
        _c(
          "div",
          { staticClass: "vx-row" },
          [
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(_vm.resources.StartDate.i18n) ||
                          _vm.resources.StartDate.name
                      ) +
                      "\n        "
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.start_date,
                    callback: function($$v) {
                      _vm.start_date = $$v
                    },
                    expression: "start_date"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col w-full md:w-1/5 mb-2" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.EndDate.i18n) ||
                        _vm.resources.EndDate.name
                    )
                  )
                ]),
                _c("datepicker", {
                  attrs: {
                    language: _vm.languages[_vm.language],
                    format: "d MMMM yyyy"
                  },
                  model: {
                    value: _vm.end_date,
                    callback: function($$v) {
                      _vm.end_date = $$v
                    },
                    expression: "end_date"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/6 w-full mt-6" }, [
              _c("ul", { staticClass: "vx-col w-full" }, [
                _c(
                  "li",
                  [
                    _c(
                      "vs-checkbox",
                      {
                        model: {
                          value: _vm.isCreationDate,
                          callback: function($$v) {
                            _vm.isCreationDate = $$v
                          },
                          expression: "isCreationDate"
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.CreationDate.i18n) ||
                              _vm.resources.CreationDate.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            ]),
            _c("vs-button", {
              staticClass: "mt-5",
              attrs: {
                color: "primary",
                type: "filled",
                "icon-pack": "feather",
                icon: "icon-refresh-ccw"
              },
              on: {
                click: function($event) {
                  return _vm.cleanDate()
                }
              }
            }),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Supplier.i18n) ||
                          _vm.resources.Supplier.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.supplierOption,
                      reduce: function(gds) {
                        return gds.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.supplierId,
                      callback: function($$v) {
                        _vm.supplierId = $$v
                      },
                      expression: "supplierId"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Country.i18n) ||
                          _vm.resources.Country.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.countryOption,
                      reduce: function(item) {
                        return item.countryCode
                      },
                      label: "countryName"
                    },
                    model: {
                      value: _vm.country,
                      callback: function($$v) {
                        _vm.country = $$v
                      },
                      expression: "country"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Source.i18n) ||
                          _vm.resources.Source.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.sourceOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.source,
                      callback: function($$v) {
                        _vm.source = $$v
                      },
                      expression: "source"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/5 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("label", { staticClass: "vs-input--label" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Type.i18n) ||
                          _vm.resources.Type.name
                      )
                    )
                  ]),
                  _c("v-select", {
                    attrs: {
                      clearable: true,
                      options: _vm.typeOption,
                      reduce: function(item) {
                        return item.id
                      },
                      label: "name"
                    },
                    model: {
                      value: _vm.type,
                      callback: function($$v) {
                        _vm.type = $$v
                      },
                      expression: "type"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col w-full md:w-1/6 mb-2" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "alpha_dash",
                        expression: "'alpha_dash'"
                      }
                    ],
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      label:
                        _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name,
                      name: "bookingReference",
                      maxlength: "20"
                    },
                    model: {
                      value: _vm.bookingReference,
                      callback: function($$v) {
                        _vm.bookingReference = $$v
                      },
                      expression: "bookingReference"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col mb-2 mt-5" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { color: "primary", type: "filled" },
                    on: {
                      click: function($event) {
                        return _vm.get()
                      }
                    }
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Search.i18n) ||
                          _vm.resources.Search.name
                      )
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "vx-card",
        { staticClass: "mb-4" },
        [
          _c(
            "vs-collapse",
            [
              _c(
                "vs-collapse-item",
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t(_vm.resources.Summary.i18n) ||
                            _vm.resources.Summary.i18n
                        ) +
                        "\n        "
                    )
                  ]),
                  _c(
                    "vs-row",
                    {
                      staticClass: "mb-4",
                      attrs: { "vs-type": "flex", "vs-justify": "center" }
                    },
                    [
                      _c(
                        "vs-col",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            "vs-type": "flex",
                            "vs-justify": "center",
                            "vs-align": "center",
                            "vs-w": "2",
                            "vs-sm": "3",
                            "vs-xs": "12"
                          }
                        },
                        [
                          _c(
                            "vx-card",
                            {
                              attrs: {
                                "card-background": "success",
                                "content-color": "#fff"
                              }
                            },
                            [
                              _c(
                                "h5",
                                { staticClass: "text-center text-white" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.InitialAgent.i18n
                                        ) || _vm.resources.InitialAgent.i18n
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c(
                                "h1",
                                { staticClass: "text-center text-white" },
                                [
                                  _c("strong", [
                                    _vm._v("$" + _vm._s(_vm.initialAgent))
                                  ])
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.summaryTeams.length > 0
                ? _c("vs-collapse-item", [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.$t(_vm.resources.SupportTeam.i18n) ||
                              _vm.resources.SupportTeam.i18n
                          ) +
                          "\n        "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-row mb-base" },
                      _vm._l(_vm.summaryTeams, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.idx,
                            staticClass: "vx-col w-full md:w-1/2 mb-base"
                          },
                          [
                            _c("h5", { staticClass: "text-center" }, [
                              _vm._v(_vm._s(item.area))
                            ]),
                            _c(
                              "div",
                              [
                                _c(
                                  "vs-table",
                                  {
                                    attrs: { data: item.children },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var data = ref.data
                                            return _vm._l(data, function(
                                              tr,
                                              indextr
                                            ) {
                                              return _c(
                                                "vs-tr",
                                                { key: indextr },
                                                [
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data:
                                                          data[indextr]
                                                            .initialAgent
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data[indextr]
                                                            .initialAgent
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "vs-td",
                                                    {
                                                      attrs: {
                                                        data:
                                                          data[indextr]
                                                            .initialAgentCommission
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          data[indextr]
                                                            .initialAgentCommission
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              )
                                            })
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c(
                                      "template",
                                      { slot: "thead" },
                                      [
                                        _c(
                                          "vs-th",
                                          { attrs: { align: "center" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.InitialAgent
                                                    .i18n
                                                ) ||
                                                  _vm.t(
                                                    _vm.resources.InitialAgent
                                                      .name
                                                  )
                                              )
                                            )
                                          ]
                                        ),
                                        _c("vs-th", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                _vm.resources.Value.i18n
                                              ) ||
                                                _vm.t(_vm.resources.Value.name)
                                            )
                                          )
                                        ])
                                      ],
                                      1
                                    )
                                  ],
                                  2
                                )
                              ],
                              1
                            ),
                            _c(
                              "h5",
                              {
                                staticClass:
                                  "text-center text-success font-bold"
                              },
                              [
                                _vm._v(
                                  "\n              $\n              " +
                                    _vm._s(
                                      item.children
                                        .map(function(x) {
                                          return x.initialAgentCommission
                                        })
                                        .reduce(function(y, amount) {
                                          return y + amount
                                        })
                                        .toLocaleString("es-MX")
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "ag-grid-demo" } },
        [
          _c(
            "vx-card",
            [
              _c(
                "div",
                { staticClass: "flex flex-wrap justify-between items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-4 md:mb-0 mr-4 ag-grid-table-actions-left"
                    },
                    [
                      _c(
                        "vs-dropdown",
                        {
                          staticClass: "cursor-pointer",
                          attrs: { "vs-trigger-click": "" }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                            },
                            [
                              _c("span", { staticClass: "mr-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.currentPage * _vm.paginationPageSize -
                                      (_vm.paginationPageSize - 1)
                                  ) +
                                    "\n                -\n                " +
                                    _vm._s(
                                      _vm.dataList.length -
                                        _vm.currentPage *
                                          _vm.paginationPageSize >
                                        0
                                        ? _vm.currentPage *
                                            _vm.paginationPageSize
                                        : _vm.dataList.length
                                    ) +
                                    "\n                of " +
                                    _vm._s(_vm.dataList.length)
                                )
                              ]),
                              _c("feather-icon", {
                                attrs: {
                                  icon: "ChevronDownIcon",
                                  svgClasses: "h-4 w-4"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-dropdown-menu",
                            [
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        20
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("20")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        50
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("50")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        100
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("100")])]
                              ),
                              _c(
                                "vs-dropdown-item",
                                {
                                  on: {
                                    click: function($event) {
                                      return _vm.gridApi.paginationSetPageSize(
                                        150
                                      )
                                    }
                                  }
                                },
                                [_c("span", [_vm._v("150")])]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex flex-wrap items-center justify-between ag-grid-table-actions-right"
                    },
                    [
                      _c("vs-input", {
                        staticClass: "mb-4 md:mb-0 mr-4",
                        attrs: {
                          placeholder:
                            _vm.$t(_vm.resources.Search.i18n) ||
                            _vm.resources.Search.name
                        },
                        on: { input: _vm.updateSearchQuery },
                        model: {
                          value: _vm.searchQuery,
                          callback: function($$v) {
                            _vm.searchQuery = $$v
                          },
                          expression: "searchQuery"
                        }
                      }),
                      _c(
                        "vs-button",
                        {
                          staticClass: "mb-4 md:mb-0",
                          on: {
                            click: function($event) {
                              return _vm.gridApi.exportDataAsCsv()
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t(_vm.resources.ExportAs.i18n) ||
                                  _vm.resources.ExportAs.name
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ]
              ),
              _c("ag-grid-vue", {
                staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
                attrs: {
                  gridOptions: _vm.gridOptions,
                  columnDefs: _vm.columnDefs,
                  defaultColDef: _vm.defaultColDef,
                  rowData: _vm.dataList,
                  rowSelection: "single",
                  colResizeDefault: "shift",
                  animateRows: true,
                  floatingFilter: true,
                  pagination: true,
                  paginationPageSize: _vm.paginationPageSize,
                  suppressPaginationPanel: true
                },
                on: { "row-selected": _vm.onRowSelected }
              }),
              _c("vs-pagination", {
                attrs: { total: _vm.totalPages, max: _vm.maxPageNumbers },
                model: {
                  value: _vm.currentPage,
                  callback: function($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }